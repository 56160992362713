/* Global Styles */
h1, h2, h3, h4, h5, p {
    font-family: monospace;
    font-weight: 400;
    font-style: normal;
    color: white;
    font-size: 16px; /* Default size */
}

/* Mobile-friendly font size */
@media only screen and (max-width: 768px) {
    h1, h2, h3, h4, h5, p {
        font-size: 10px;
    }
}

/* Line break spacing */
br {
    margin: 8px 0;
}

/* Loading animation */
.loading::after {
    display: inline-block;
    animation: dotty steps(1, end) 1s infinite;
    content: '';
}

@keyframes dotty {
    0% { content: '   '; }
    25% { content: '. '; }
    50% { content: '.. '; }
    75% { content: '...'; }
    100% { content: '   '; }
}

/* Blinking Cursor */
.blinking-cursor {
    background-color: #fff;
    width: 0.8em;
    height: 0.15em;
    animation: blink 0.65s step-end infinite;
}

@keyframes blink {
    from, to { background-color: transparent; }
    50% { background-color: white; }
}

/* BIOS Start Button */
.bios-start-button {
    background-color: #000;
    border: 4px solid #fff;
    border-width: 4px 3px;
}

.bios-start-button p {
    box-sizing: border-box;
    padding: 8px;
}

.bios-start-button:hover p {
    background-color: #fff;
    cursor: pointer;
    color: #000;
}

/* Loading Screen Layout */
.loading-screen-header,
.loading-screen-body,
.loading-screen-footer {
    padding: 48px;
}

.loading-screen-footer {
    padding-bottom: 64px;
}

.info-wrapper {
    top: 64px;
    left: 64px;
}

/* Mobile Adjustments */
@media only screen and (max-width: 768px) {
    .loading-screen-header,
    .loading-screen-body,
    .loading-screen-footer {
        padding: 16px;
    }

    .info-wrapper {
        top: 16px;
        left: 16px;
    }

    .blinking-cursor {
        width: 0.4em;
        height: 0.08em;
    }

    .loading-screen-footer {
        padding-bottom: 32px;
    }
}